<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col>
        <v-card :loading="serviceStatus.loading || userCount.loading">
          <v-card-title class="d-flex justify-space-between">
            <div class="d-flex align-center">
              Services Status

              <v-avatar
                v-if="serviceStatus.data"
                size="15"
                :color="
                  serviceStatus.data.healthStatus === 0 &&
                  serviceStatus.data.errorServices.length === 0
                    ? 'success'
                    : 'error'
                "
                class="ml-2"
              ></v-avatar>
            </div>

            <div>
              <v-chip outlined class="mx-1">
                <v-avatar left>
                  <v-icon>mdi-account-multiple</v-icon>
                </v-avatar>

                {{
                  userCount.data && userCount.data > 0 ? userCount.data : "-"
                }}
              </v-chip>

              <v-btn
                @click="fetchData"
                :loading="serviceStatus.loading || userCount.loading"
                icon
                class="mx-1"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>

              <v-menu left offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon class="mx-1">
                    <v-icon>mdi-cog</v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title>
                    {{ $t("servicesStatus.settings") }}
                  </v-card-title>

                  <v-divider></v-divider>

                  <v-list>
                    <v-list-item>
                      <v-list-item-action>
                        <v-switch
                          id="autoRefresh"
                          v-model="autoRefresh"
                        ></v-switch>
                      </v-list-item-action>

                      <v-list-item-title>
                        <v-label for="autoRefresh">
                          {{ $t("servicesStatus.autoRefresh") }}
                        </v-label>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </div>
          </v-card-title>
          <v-expansion-panels
            v-if="serviceStatus.data"
            accordion
            hover
            mandatory
            multiple
          >
            <v-expansion-panel
              v-for="(serviceData, serviceName) in services"
              :key="serviceData.id"
            >
              <v-expansion-panel-header>
                <div class="d-flex align-center font-weight-bold">
                  {{ serviceName }}
                  <v-avatar
                    size="10"
                    :color="getStatusColor(serviceName, serviceData)"
                    class="ml-2"
                  ></v-avatar>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="dataTable">
                  <div>
                    {{ $t("servicesStatus.table.version") }}
                  </div>
                  <div>{{ serviceData.version }}</div>
                  <div>
                    {{ $t("servicesStatus.table.memoryUsage") }}
                  </div>
                  <div>{{ serviceData.memory_MB }} MB</div>
                  <div>
                    {{ $t("servicesStatus.table.cpuUsage") }}
                  </div>
                  <div>{{ serviceData.cpuUsage_percent }}%</div>
                  <div>
                    {{ $t("servicesStatus.table.timestamp") }}
                  </div>
                  <div>{{ $d(serviceData.timestamp, "longDateTime") }}</div>
                  <div>
                    {{ $t("servicesStatus.table.uptime") }}
                  </div>
                  <div>{{ formatUptime(serviceData.uptime_ms) }}</div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Repository from "../store/api/repository";

export default {
  name: "Status",
  data() {
    return {
      serviceStatus: {
        data: null,
        loading: false
      },
      userCount: {
        data: null,
        loading: false
      },
      settings: {
        autoRefresh: {
          intervalId: null
        }
      }
    };
  },
  computed: {
    services() {
      return this.serviceStatus.data?.serviceStates || null;
    },
    autoRefresh: {
      get() {
        return this.$store.state.settings.status.autoRefresh;
      },
      set(value) {
        this.$store.commit("settings/setStatusAutoRefresh", value);
      }
    }
  },
  watch: {
    autoRefresh(value) {
      if (value) {
        this.setAutoRefreshInterval();
      } else {
        clearInterval(this.settings.autoRefresh.intervalId);
        this.settings.autoRefresh.intervalId = null;
      }
    }
  },
  methods: {
    fetchServiceStatus() {
      return new Promise((resolve, reject) => {
        Repository.get(`/debug/health`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    fetchUserCount() {
      return new Promise((resolve, reject) => {
        Repository.get(`/debug/users`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    fetchData() {
      this.serviceStatus.loading = true;
      this.userCount.loading = true;

      this.fetchServiceStatus()
        .then(response => {
          this.serviceStatus.data = response.data.data[0];
          this.serviceStatus.loading = false;
        })
        .catch(() => {
          this.serviceStatus.loading = false;
        });

      this.fetchUserCount()
        .then(response => {
          this.userCount.data = response.data.data[0];
          this.userCount.loading = false;
        })
        .catch(() => {
          this.userCount.loading = false;
        });
    },
    setAutoRefreshInterval() {
      const self = this;

      this.settings.autoRefresh.intervalId = setInterval(function() {
        self.fetchData();
      }, 10000);
    },
    getStatusColor(serviceName, serviceData) {
      const isErrorService = this.serviceStatus.data.errorServices.includes(
        serviceName
      );

      if (serviceData.status === 0 && !isErrorService) {
        return "success";
      }
      return "error";
    },
    formatUptime(timestamp) {
      let remainder = timestamp;

      const days = String(Math.floor(remainder / 86400000));
      remainder = Math.floor(remainder % 86400000);
      const hours = String(Math.floor(remainder / 3600000)).padStart(2, "0");
      remainder = Math.floor(remainder % 3600000);
      const minutes = String(Math.floor(remainder / 60000)).padStart(2, "0");
      remainder = Math.floor(remainder % 60000);
      const seconds = String(Math.floor(remainder / 1000)).padStart(2, "0");
      remainder = Math.floor(remainder % 1000);

      let timeString = `${hours}:${minutes}:${seconds}h`;

      if (days > 0) {
        timeString = `${days}d ${timeString}`;
      }

      return timeString;
    }
  },
  mounted() {
    this.fetchData();

    if (this.autoRefresh && !this.settings.autoRefresh.intervalId) {
      this.setAutoRefreshInterval();
    }
  }
};
</script>

<style scoped>
div.dataTable {
  display: grid;
  grid-template-columns: max-content max-content;
  column-gap: 1em;
  row-gap: 0.5em;
}
</style>
